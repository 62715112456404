import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

var firebaseConfig = {
  // apiKey: "AIzaSyAnHXLUZyYOLHeljvg_fWnbiU19wifmKqY",
  // authDomain: "prepro-app-principal.firebaseapp.com",
  // databaseURL: "https://prepro-app-principal-default-rtdb.firebaseio.com",
  // projectId: "prepro-app-principal",
  // storageBucket: "prepro-app-principal.appspot.com",
  // messagingSenderId: "809037308580",
  // appId: "1:809037308580:web:940702b2a898eb01f87c31",
  // measurementId: "G-9F8VPGS9SB",

  apiKey: "AIzaSyCRk92ZMi2sYtHWuH2p2hQC7qbLmUND4uc",
  authDomain: "asambleageneral-d7e87.firebaseapp.com",
  databaseURL: "https://asambleageneral-d7e87-default-rtdb.firebaseio.com",
  projectId: "asambleageneral-d7e87",
  storageBucket: "asambleageneral-d7e87.appspot.com",
  messagingSenderId: "994241614597",
  appId: "1:994241614597:web:f98d75bba837edbf229d6c",
  measurementId: "G-ETN4HKZQ7S",

  // apiKey: "AIzaSyCeZjkP237icX5T3NZLPHPClbBGF6qmSnc",
  // authDomain: "asambleageneral-314d8.firebaseapp.com",
  // databaseURL: "https://asambleageneral-314d8-default-rtdb.firebaseio.com",
  // projectId: "asambleageneral-314d8",
  // storageBucket: "gs://asambleageneral-314d8.appspot.com",

  // messagingSenderId: "5284122877",
  // appId: "1:5284122877:web:1855c4be2f141c78c3ee94",
  // measurementId: "G-77LCZ83BY2",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
